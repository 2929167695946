




import {Component, Vue} from 'vue-property-decorator';
import Overview from '@/components/Overview.vue';

@Component({
  components: {
    Overview,
  },
})
export default class TargetedReviewOverview extends Vue {
}
